<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">REGIERUNG VON MITTELFRANKEN</h2>
                <h3>Grußwort zu Rosch HaSchana 5785</h3>
                <img src="assets/img/engelhardt.jpg" class="rounded float-left" height="360px"
                    style="padding-right: 15px; padding-bottom: 15px" alt="engelhardt">
                <p>Auch ich grüße Sie herzlich zu Rosch HaSchana und wünsche Ihnen, Ihren Familien,
                    Freundinnen und Freunden von ganzen Herzen ein gesegnetes Neujahrsfest.</p>
                <p>Leider müssen wir auf ein Jahr zurückblicken, das viel Unheil und Leid gebracht hat.
                    Seit dem 7. Oktober 2023 ist für Juden in Israel und weltweit nichts mehr so, wie es
                    vorher war. Gleich zu Beginn des letzten Jahres erfuhren wir mit Entsetzen von den
                    unmenschlichen, barbarischen Terrorangriffen der Hamas auf die Menschen in Israel.
                    Und noch immer warten verschleppte Geiseln und ihre Angehörigen verzweifelt auf ihre
                    Freilassung.</p>
                <p>Der Rückblick auf das vergangene Jahr ist deshalb mit sehr viel Trauer, Schmerz und
                    Verzweiflung verbunden. Wir gedenken der Opfer des Terrors und unser tiefes Mitgefühl
                    gilt ihren Familien, Angehörigen, Freundinnen und Freunden.</p>
                <p>Es ist erschreckend und beschämend zugleich, dass Jüdinnen und Juden sich in
                    unserem Land auch 76 Jahre nach Gründung des Staates Israel nicht sicher fühlen.
                    Umso mehr brauchen sie jetzt die Unterstützung der breiten Zivilgesellschaft. Nur wenn
                    viele Menschen für die in unserer Verfassung garantierten Rechte einstehen, ist ein
                    Zusammenleben in Frieden und ohne Diskriminierung möglich. Deshalb müssen wir alle
                    weiterhin entschlossen gegen jede Form von Antisemitismus und Rassismus vorgehen.
                    Insbesondere auch dann, wenn Hass und Diskriminierungen in den sozialen Medien
                    verbreitet werden.</p>
                <p>In den letzten Monaten haben tausende von Menschen bei vielen Demonstrationen
                    gegen Antisemitismus in ganz Deutschland ihre Solidarität zum Ausdruck gebracht. Und
                    das ist gut so!</p>
                <p>Lassen Sie uns daran anknüpfen und angesichts der fortwährenden Kriege, unter
                    anderem in der Ukraine und in Nahost, gemeinsam Zeichen des Friedens setzen und
                    Licht in die Dunkelheit bringen!</p>
                <p>
                    In diesem Sinne wünsche ich Ihnen für das neue Jahr vor allem Frieden, Hoffnung und
                    Zuversicht! Und mögen Glück und Gesundheit Sie durch das neue Jahr 5785 begleiten!<br />
                    <b>Shana tova umetuka – ein segensreiches neues Jahr 5785</b>
                </p>
                <p>Dr. Kerstin Engelhardt-Blum, <br />Regierungspräsidentin</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>