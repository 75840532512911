<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">Grußwort</h2>
                <h3>des Oberbürgermeisters der Stadt Nürnberg</h3>
                <img src="assets/img/koenig.jpg" class="rounded float-left" height="360px"
                    style="padding-right: 15px; padding-bottom: 15px" alt="Marcus Koenig">
                <p>Sehr geehrte Damen und Herren,
                    liebe Mitglieder der jüdischen orthodoxen Gemeinde Nürnberg,</p>
                <p>in Verbundenheit übermittle ich herzliche Wünsche zum bevorstehenden Rosch ha-
                    Schana 5785! Alles Gute für das neue Jahr!</p>
                <p>Möge das neue Jahr Ihnen und Ihren Familien Gesundheit, Glück und Erfolg bringen.
                    Mögen Sie die Gelegenheit nutzen, um Ihre Beziehungen zu stärken, Ihre Ziele zu
                    verfolgen und Ihre Träume zu verwirklichen. Es ist eine Zeit der Besinnung, des
                    Rückblicks auf das vergangene Jahr und des Ausblicks auf das kommende Jahr. Es
                    ist eine Zeit, um über unsere Handlungen nachzudenken und uns auf unsere Ziele
                    und Werte zu besinnen.</p>
                <p>Der sich demnächst jährende Terrorangriff der Hamas auf Israel überschattet Ihr
                    Neujahrsfest sicherlich. Vielleicht haben Sie Verwandte, Freunde oder Bekannte in
                    Israel und sorgen sich um sie und um die Situation in Israel. Und ja, auch hierzulande
                    gibt es Antisemitismus, Gewalt und Angriffe. Ich versichere Ihnen: Die Stadt
                    Nürnberg und ich persönlich stehen an Ihrer Seite.</p>
                <p>Rosch ha-Schana erinnert uns daran, die Bedeutung der Gemeinschaft zu schätzen.
                    Als Angehörige der jüdischen orthodoxen Gemeinde Nürnberg sind Sie Teil einer
                    starken und engagierten Gemeinschaft. Außerdem sind Sie ein wichtiger Teil
                    Nürnbergs – ganz nach meinem Motto: „Wir alle sind Nürnberg.“</p>
                <p>In diesem Sinne wünsche ich Ihnen eine inspirierende und bedeutungsvolle Rosch
                    ha-Schana-Feier. Mögen Sie die Tage des Neujahrs mit Freude, Frieden und Liebe
                    verbringen.</p>
                <p>Ich wünsche allen Jüdinnen und Juden in Nürnberg, dass für sie 5785 ein gutes und
                    friedliches Jahr werden möge.</p>
                <p>שנה טובה ומתוקה – ein gutes und süßes neues Jahr!</p>
                <p>Marcus König, <br />Oberbürgermeister</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>