<div class="container">
    <app-header></app-header>
</div>

<main role="main" class="container">
    <div class="row">
        <div class="col-md-8 blog-main">
            <div class="blog-post">

                <h2 class="blog-post-title">Grußwort Rosch Haschana 5785</h2>
                <h3>Jüdisches Neujahrsfest 2024</h3>
                <img src="assets/img/soeder.jpg" class="rounded float-left" height="360px"
                    style="padding-right: 15px; padding-bottom: 15px" alt="Markus Soeder">
                <p>Zum Neujahrsfest einen herzlichen Gruß an alle jüdischen Bürgerinnen
                    und Bürger in Bayern!</p>
                <p>Bei Rosch Haschana geht es um das Innehalten und die innere Einkehr.
                    Die Gläubigen blicken zurück auf das vergangene Jahr und festigen ihre
                    Verbindung zu Gott, um zuversichtlich und mutig in das neue Jahr zu gehen. Die Traditionen und
                    Bräuche, die sie dabei pflegen, gehören untrennbar zur vielfältigen Kultur in Bayern. Jüdisches
                    Leben ist fester Bestandteil
                    unserer Gesellschaft! Dafür gilt es geschlossen und konsequent einzutreten, worauf es angesichts
                    einer neuen Welle von Antisemitismus und Extremismus aktuell ganz besonders ankommt!</p>
                <p>Die Bayerische Staatsregierung gibt ein unbedingtes Schutzversprechen
                    für jüdisches Leben im Freistaat ab. Im Bewusstsein um die historische
                    Verantwortung bekämpft sie Hass und Hetze mit ganzer Kraft und fördert
                    Toleranz und Freiheit. Die Bayerische Staatsregierung setzt sich tatkräftig
                    dafür ein, dass unsere jüdischen Mitbürgerinnen und Mitbürger sicher und
                    unbeschwert in Bayern leben können. Jetzt und auch in Zukunft!</p>
                <p>Ein gesundes und glückliches neues Jahr! Shanah Tova!</p>

                <p>Dr. Markus Söder, <br />Ministerpräsident des Freistaats Bayern</p>
            </div><!-- /.blog-post -->

        </div><!-- /.blog-main -->

        <aside class="col-md-4 blog-sidebar">
            <app-info-box></app-info-box>
        </aside><!-- /.blog-sidebar -->
    </div><!-- /.row -->

</main><!-- /.container -->

<app-related-articles></app-related-articles>