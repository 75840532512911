import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MikweComponent } from './mikwe/mikwe.component';
import { RabbinatComponent } from './rabbinat/rabbinat.component';
import { ContactFormularComponent } from './contact-formular/contact-formular.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { SpendeComponent } from './spende/spende.component';
import { JudentumZuhauseComponent } from './judentum-zuhause/judentum-zuhause.component';
import { JugendclubComponent } from './jugendclub/jugendclub.component';
import { KalenderComponent } from './kalendar/kalender.component';
import { KinderbetreuungComponent } from './kinderbetreuung/kinderbetreuung.component';
import { KinderunterrichtComponent } from './kinderunterricht/kinderunterricht.component';
import { KoscheresEssenComponent } from './koscheres-essen/koscheres-essen.component';
import { SynagogeComponent } from './synagoge/synagoge.component';
import { ToraLernenComponent } from './tora-lernen/tora-lernen.component';
import { BesuchInDerSynagogeComponent } from './besuch-in-der-synagoge/besuch-in-der-synagoge.component';
import { MediathekComponent } from './mediathek/mediathek.component';
import { PublikationenComponent } from './publikationen/publikationen.component';
import { RedirectComponent } from './redirect/redirect.component';
import { EngelhardtComponent } from './engelhardt/engelhardt.component';
import { SoederComponent } from './soeder/soeder.component';
import { KoenigComponent } from './koenig/koenig.component';


const routes: Routes = [
  { path: '', component: StartPageComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'mikwe', component: MikweComponent },
  { path: 'judentum-zu-hause', component: JudentumZuhauseComponent },
  { path: 'jugendclub', component: JugendclubComponent },
  { path: 'kalender', component: KalenderComponent },
  { path: 'kinder-betreuung', component: KinderbetreuungComponent },
  { path: 'kinder-unterricht', component: KinderunterrichtComponent },
  { path: 'koscheres-essen', component: KoscheresEssenComponent },
  { path: 'synagoge', component: SynagogeComponent },
  { path: 'tora-lernen', component: ToraLernenComponent },
  { path: 'rabbinat', component: RabbinatComponent },
  { path: 'kontakt', component: ContactFormularComponent },
  { path: 'besuch-in-der-synagoge', component: BesuchInDerSynagogeComponent },
  { path: 'spenden', component: SpendeComponent },
  { path: 'mediathek', component: MediathekComponent },
  { path: 'publikationen', component: PublikationenComponent },
  { path: 'pessach-checkliste', component: RedirectComponent },
  { path: 'sefirat-haomer', component: RedirectComponent },
  { path: 'netilat-jadaim', component: RedirectComponent },
  { path: 'dr-engelhardt-blum-mittelfranken', component: EngelhardtComponent },
  { path: 'dr-soeder-ministerpraesident-bayern', component: SoederComponent },
  { path: 'marcus-koenig-oberbuergermeister', component: KoenigComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
