<div class="container-fluid">
    <!-- Row 1: Three Cards -->
    <div class="row">
        <div class="col-md-4">
            <a href="/dr-soeder-ministerpraesident-bayern" class="clickable-card text-decoration-none">
                <div
                    class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-350 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-primary">Markus Söder</strong>
                        <h3 class="mb-0">Ministerpräsident <br />des Freistaates Bayern</h3>
                        <p class="card-text mb-auto">Grußwort zum Jüdischen Neuen Jahr 5785</p>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                        <img src="assets/img/soeder.jpg" class="rounded img-fluid p-2 card-img" alt="Dr. Markus Söder">
                    </div>
                </div>
            </a>
        </div>

        <div class="col-md-4">
            <a href="/marcus-koenig-oberbuergermeister" class="clickable-card text-decoration-none">
                <div
                    class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-350 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-primary">Marcus König</strong>
                        <h3 class="mb-0">Oberbürgermeister <br />der Stadt Nürnberg</h3>
                        <p class="card-text mb-auto">Grußwort zum Jüdischen Neuen Jahr 5785</p>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                        <img src="assets/img/koenig.jpg" class="rounded img-fluid p-2 card-img" alt="Marcus König">
                        <!-- style="object-fit: cover; width: 100%; height: 200px;"> -->
                    </div>
                </div>
            </a>
        </div>

        <div class="col-md-4">
            <a href="/dr-engelhardt-blum-mittelfranken" class="clickable-card text-decoration-none">
                <div
                    class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-350 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-primary">Kerstin Engelhardt-Blum</strong>
                        <h3 class="mb-0">Regierungspräsidentin des Regierungsbezirks Mittelfranken</h3>
                        <p class="card-text mb-auto">Grußwort zum Jüdischen Neuen Jahr 5785</p>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                        <img src="assets/img/engelhardt.jpg" class="rounded img-fluid p-2 card-img"
                            alt="Dr. Kerstin Engelhardt-Blum">
                        <!-- style="object-fit: cover; width: 100%; height: 200px;"> -->

                    </div>
                </div>
            </a>
        </div>
    </div>

    <!-- Row 2: Offset Card -->
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div
                class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-350 position-relative bg-light-blue">
                <div class="col p-4 d-flex flex-column position-static">
                    <strong class="d-inline-block mb-2 text-primary">Bet Hamikdasch</strong>
                    <h3 class="mb-0">Unser Tempel</h3>
                    <p class="card-text mb-auto">
                        Unser heiliger Tempel. Wie er war, wie er wird.... <br>
                        Infos von
                    </p>
                    <a href="http://templeinstitute.org" target="_blank" class="stretched-link">templeinstitute.org</a>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <img src="assets/img/bet-hamikdash.png" class="rounded img-fluid p-2 card-img" alt="Bet Hamikdasch">
                    <!-- style="object-fit: cover; width: 100%; height: 200px;"> -->
                </div>
            </div>
        </div>
    </div>
</div>