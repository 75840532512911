import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soeder',
  templateUrl: './soeder.component.html',
  styleUrls: ['./../app.component.css']
})
export class SoederComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
