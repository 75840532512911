import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StartPageComponent } from './start-page/start-page.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { HeaderComponent } from './header/header.component';
import { MikweComponent } from './mikwe/mikwe.component';
import { RabbinatComponent } from './rabbinat/rabbinat.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { ContactFormularComponent } from './contact-formular/contact-formular.component';
import { FormsModule } from '@angular/forms';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { RelatedArticlesComponent } from './related-articles/related-articles.component';
import { SpendeComponent } from './spende/spende.component';
import { HttpClientModule } from '@angular/common/http';
import { SynagogeComponent } from './synagoge/synagoge.component';
import { KinderunterrichtComponent } from './kinderunterricht/kinderunterricht.component';
import { KinderbetreuungComponent } from './kinderbetreuung/kinderbetreuung.component';
import { KoscheresEssenComponent } from './koscheres-essen/koscheres-essen.component';
import { JudentumZuhauseComponent } from './judentum-zuhause/judentum-zuhause.component';
import { ToraLernenComponent } from './tora-lernen/tora-lernen.component';
import { JugendclubComponent } from './jugendclub/jugendclub.component';
import { KalenderComponent } from './kalendar/kalender.component';
import { BesuchInDerSynagogeComponent } from './besuch-in-der-synagoge/besuch-in-der-synagoge.component';
import { MediathekComponent } from './mediathek/mediathek.component';
import { PublikationenComponent } from './publikationen/publikationen.component';
import { RedirectComponent } from './redirect/redirect.component';
import { EngelhardtComponent } from './engelhardt/engelhardt.component';
import { SoederComponent } from './soeder/soeder.component';
import { KoenigComponent } from './koenig/koenig.component';

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    ImpressumComponent,
    HeaderComponent,
    MikweComponent,
    RabbinatComponent,
    InfoBoxComponent,
    ContactFormularComponent,
    DatenschutzComponent,
    RelatedArticlesComponent,
    SpendeComponent,
    SynagogeComponent,
    KinderunterrichtComponent,
    KinderbetreuungComponent,
    KoscheresEssenComponent,
    JudentumZuhauseComponent,
    ToraLernenComponent,
    JugendclubComponent,
    KalenderComponent,
    BesuchInDerSynagogeComponent,
    MediathekComponent,
    PublikationenComponent,
    SoederComponent,
    KoenigComponent,
    EngelhardtComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
